type Environment = {
	build_env: string;
	fed_env: string;
};

export const getEnvironment = async (): Promise<Environment> => {
	const file = await fetch('/environment.json');

	return await file.json();
};
